import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';

interface MyGProps extends React.ComponentProps<G> {
  children: React.ReactNode;
}

const MyG = ({ ...props }: MyGProps) => {
  return <G {...props} />;
};
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1444_1869"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="22"
    >
      <rect width="22" height="22" fill="#D9D9D9" />
    </mask>
    <MyG mask="url(#mask0_1444_1869)">
      <Path
        d="M4.6724 14.7138C4.33628 14.1332 4.0842 13.5374 3.91615 12.9263C3.74809 12.3152 3.66406 11.6888 3.66406 11.0471C3.66406 8.99991 4.37448 7.25825 5.79531 5.82214C7.21615 4.38602 8.95017 3.66797 10.9974 3.66797H11.1578L9.69115 2.2013L10.9745 0.917969L14.6411 4.58464L10.9745 8.2513L9.69115 6.96797L11.1578 5.5013H10.9974C9.46962 5.5013 8.17101 6.03984 7.10156 7.11693C6.03212 8.19401 5.4974 9.50408 5.4974 11.0471C5.4974 11.4444 5.54323 11.8339 5.6349 12.2159C5.72656 12.5978 5.86406 12.9721 6.0474 13.3388L4.6724 14.7138ZM11.0203 21.0846L7.35365 17.418L11.0203 13.7513L12.3036 15.0346L10.837 16.5013H10.9974C12.5252 16.5013 13.8238 15.9628 14.8932 14.8857C15.9627 13.8086 16.4974 12.4985 16.4974 10.9555C16.4974 10.5582 16.4516 10.1687 16.3599 9.78672C16.2682 9.40478 16.1307 9.03047 15.9474 8.6638L17.3224 7.2888C17.6585 7.86936 17.9106 8.46519 18.0786 9.0763C18.2467 9.68741 18.3307 10.3138 18.3307 10.9555C18.3307 13.0027 17.6203 14.7444 16.1995 16.1805C14.7786 17.6166 13.0446 18.3346 10.9974 18.3346H10.837L12.3036 19.8013L11.0203 21.0846Z"
        fill="#1C1B1F"
      />
    </MyG>
  </Svg>
);
function SvgSubscribe({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgSubscribe;
